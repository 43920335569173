<template>
    <div>
        <div v-if="selectedTab === 0" style="position: relative;">
            <div class="sidebar-slider" v-show="showAssignSlide">
                <v-navigation-drawer absolute v-model="showAssignSlide" :height="drawerHeight" right hide-overlay width="50%"
                    class="merchandise-drawer merchandise-drawer-property">
                    <v-app-bar color="#E0F5F5" elevation="0">
                        <div class="title">
                            <hb-icon class="ml-n1">mdi-square-edit-outline</hb-icon> Update Products ({{ selectedProducts.length
                            }} Products)
                        </div>
                        <v-spacer></v-spacer>
                        <v-row justify="end">
                            <hb-btn icon tooltip="Close" @click="hideSlideEvent" active-state-off>
                                mdi-close
                            </hb-btn>
                        </v-row>
                    </v-app-bar>
                    <div class="main-content-box">
                        <!-- <div v-if="!bulkEditOption">
                            <div class="description-box hb-text-night-light pa-4 white">
                                Create 2-3 sentences that describes the overview of this form.
                            </div>
                            <div class="bulk-buttons pa-6">
                                <div class="d-flex justify-space-between pa-3 button-option mb-3 cursor-pointer" @click="bulkEditOption='add-inventory'">
                                    <div class="btn-title">Add Inventory</div>
                                    <hb-icon>mdi-chevron-right</hb-icon>
                                </div>
    
                                <div class="d-flex justify-space-between pa-3 button-option cursor-pointer" @click="bulkEditOption='remove-inventory'">
                                    <div class="btn-title">Remove Inventory</div>
                                    <hb-icon>mdi-chevron-right</hb-icon>
                                </div>
                            </div>
                        </div> -->
    
                        <div>
                            <div class="description-box hb-text-night-light pa-4 white">
                                Add Inventory
                            </div>
                            <!-- <div class="d-flex pa-4 white button-otion cursor-pointer" @click="bulkEditOption=''">
                                <hb-icon large>mdi-chevron-left</hb-icon>
                                <div class="btn-title pl-3">{{ bulkEditOption=='remove-inventory' ? 'Remove' : 'Add' }} Inventory</div>
                            </div> -->
                            <div class="bulk-buttons">
                                <v-row class="mx-0 px-2 py-2" style="border-bottom: 1px solid #e1e6eb;">
                                    <v-col><strong>SKU</strong></v-col>
                                    <v-col><strong>Name</strong></v-col>
                                    <v-col><strong>Qty Added</strong></v-col>
                                    <v-col><strong>Cost</strong></v-col>
                                </v-row>
    
                                <v-row v-for="(item, index) in selectedProducts" class="mx-0 px-2 bulk-edit-item" :key="index">
                                    <v-col>{{ item.sku }}</v-col>
                                    <v-col>{{ item.name }}</v-col>
                                    <v-col>
                                        <hb-text-field
                                            placeholder="Enter Qty"
                                            v-model="item.new_quantity"
                                            type="number"
                                            v-validate="'min_value:0'"
                                            class="no-num-arrows"
                                            id="new_quantity"
                                            min="0"
                                            name="new_quantity"
                                            data-vv-name="new_quantity" data-vv-as="quantity"
                                            :error="errors.has('new_quantity')" 
                                        />
                                    </v-col>
                                    <v-col>
                                        <hb-text-field
                                            placeholder="Enter Cost"
                                            v-model="item.new_price"
                                            type="number"
                                            class="no-num-arrows"
                                            v-validate="'min_value:0'"
                                            id="new_price"
                                            min="0"
                                            name="new_price"
                                            data-vv-name="new_price" data-vv-as="quantity"
                                            :error="errors.has('new_price')"
                                            @change="fractionalValueEvent($event, index, 'new_price')"
                                        >
                                        <template v-slot:prepend-inner>
                                            $
                                        </template>
                                    </hb-text-field>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </div>
                    <v-app-bar color="white" class="footer-actions" absolute bottom elevation="0">
                        <v-spacer></v-spacer>
                        <div>
                            <hb-link class="mr-2" @click="hideSlideEvent">Cancel</hb-link>
                            <!-- <hb-btn color="primary">Save</hb-btn> -->
                            <hb-btn color="primary" :disabled="isBulkLoading || !selectedProducts.length" :loading="isBulkLoading" @click="updateProductsBulkEvent">Save</hb-btn>
                        </div>
                    </v-app-bar>
                </v-navigation-drawer>
            </div>
            <hb-data-table 
                :search="search" 
                v-if="isLoadingData || property_merchandises.length" 
                :headers="tableColumns"
                :show-select="showAssignSlide"
                v-model="selectedProducts"
                :loading="isLoadingData" 
                class="property-merchand-table"
                :items="property_merchandises">
                <template v-slot:item.data-table-select="{ isSelected, select }">
                    <v-simple-checkbox color="primary" :value="isSelected" @input="select($event)"></v-simple-checkbox>
                </template>
    
                <template v-slot:item.sku="{ item }">
                    <hb-tooltip>
                        <template v-slot:item>
                            {{ trimTextEvent({ value: item.sku, limit: 10 }) }}
                        </template>
                        <template v-slot:body>
                            {{ item.sku }}
                        </template>
                    </hb-tooltip>
                </template>
    
                <template v-slot:item.name="{ item }">
                    <hb-tooltip>
                        <template v-slot:item>
                            {{ trimTextEvent({ value: item.name, limit: 10 }) }}
                        </template>
                        <template v-slot:body>
                            {{ item.name }}
                        </template>
                    </hb-tooltip>
                </template>
                <template v-slot:item.description="{ item }">
                    <hb-tooltip>
                        <template v-slot:item>
                            {{ trimTextEvent({ value: item.description, limit: 10 }) }}
                        </template>
                        <template v-slot:body>
                            {{ item.description }}
                        </template>
                    </hb-tooltip>
                </template>
                <template v-slot:item.status="{ item }">
                    <span v-if="item.statusMark === 'In Stock'">
                        <HbIcon mdi-code="mdi-check-circle" small color="#02AD0F" /> <strong
                            class="top-2px">{{ item.statusMark }}</strong>
                    </span>
                    <span v-if="item.statusMark === 'Out of Stock'" style="color: #FB4C4C">
                        <HbIcon mdi-code="mdi-alert" small color="#FB4C4C" /> <strong
                            class="top-2px">{{ item.statusMark }}</strong>
                    </span>
                    <span v-if="item.statusMark === 'Low Stock'">
                        <HbIcon mdi-code="mdi-alert-circle" small color="#FBC944" /> <strong
                            class="top-2px">{{ item.statusMark }}</strong>
                    </span>
                </template>
    
                <template class="border-left left-border" v-slot:item.actions="{ item }">
                    <hb-menu options align-right>
                        <v-list>
                            <v-list-item @click="updateInventoryEvent(item)">
                                <v-list-item-title>Update Inventory</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="editProductEvent(item)">
                                <v-list-item-title>Edit Product</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="showDeleteDialogEvent(item)">
                                <v-list-item-title>Remove Product</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </hb-menu>
                </template>
    
                <template v-if="isLoadingData || property_merchandises.length" v-slot:footer>
                    <div class="pa-3 pl-5 cm-footer">
                        Total Inventory Cost: <strong>${{ totalCost }}</strong>
                    </div>
                </template>
            </hb-data-table>
    
            <div class="no-data-box" v-if="!isLoadingData && !property_merchandises.length">
                <p class="hb-text-light mb-0">Assign merchandise to create property inventory.</p>
                <hb-btn class="mt-3 d-inline-block" v-if="property_id" @click="showAssignProductModalEvent">Assign Merchandise</hb-btn>
            </div>
    
            <hb-modal v-model="showAssignProductModal" size="large" title="Add Merchandise">
                <template v-slot:subheader>
                    <span class="hb-text-light">
                        Choose from available merchandise to add to your inventory.
                    </span>
                </template>
                <template v-slot:content>
                    <v-col>
                        <hb-text-field v-model="searchProduct" v-validate="'required|max:45|email'" data-vv-scope="default"
                            data-vv-name="email" data-vv-as="Email" :error="errors.has('default.email')"
                            placeholder="Search Product" full box />
                    </v-col>
    
                    <hb-data-table :headers="corporateTableColumns" :loading="isLoadingCorporateData"
                        :items="corporateMerchandises" :show-select="true" v-model="selectedRows" fixed-header
                        :search="searchProduct" class="corporate-merchand-table-2 px-3 pb-3">
                        <template v-slot:item.data-table-select="{ isSelected, select }">
                            <v-simple-checkbox color="primary" :value="isSelected" @input="select($event)"></v-simple-checkbox>
                        </template>
                    </hb-data-table>
    
                </template>
                <template v-slot:right-actions>
                    <hb-btn color="primary" :disabled="isLoading($options.name) || !corporateMerchandises.length" :loading="isLoading($options.name)" @click="assignPropertiesEvent">Add</hb-btn>
                </template>
            </hb-modal>
    
            <update-inventory v-model="showUpdateInventoryModal" v-if="showUpdateInventoryModal" :property-id="property_id"
                :inventory-update-selected="inventoryUpdateSelected" @fetchData="fetchData" />
            <edit-product v-model="showEditProductModal" v-if="showEditProductModal" :property-id="property_id"
                :selected="editSelected" @fetchData="fetchData" />
    
            <hb-modal v-model="deleteDialog" size="medium" :title="'Remove  ' + (deleteItem ? deleteItem.name : '')"
                confirmation :footerCancelOption="!hasInventory">
                <template v-slot:content>
                    <div v-if="!hasInventory">
                        <div class="pl-4 pr-3 py-4">
                            Are you sure you want to remove this product from your inventory?
                        </div>
                        <div class="pl-4 pr-3 pb-4">
                            This will delete all of the product’s saved settings and will set back to default settings when
                            added back.
                        </div>
                    </div>
                    <div v-else>
                        <div class="pl-4 pr-3 py-4">
                            Products can only be removed when its inventory is 0.
                        </div>
                    </div>
                </template>
                <template v-slot:right-actions>
                    <span v-if="!hasInventory">
                        <hb-btn color="destructive" :disabled="isDeleting" @click="deleteProductEvent">Remove</hb-btn>
                        <span v-show="isDeleting">
                            <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                        </span>
                    </span>
                    <span v-else>
                        <hb-btn color="destructive" @click="deleteDialog=false">Close</hb-btn>
                    </span>
                </template>
            </hb-modal>
    
        </div>
        <div v-else>
            <v-expansion-panels v-model="selectedPanel">
                <hb-expansion-panel>
                    <template v-slot:title>
                        Reordering Schedule Task 
                    </template>
                    <template v-slot:actions>
                        <hb-switch v-model="setTaskOption" right>
                            <template v-slot:label>
                                On
                            </template>
                        </hb-switch>
                    </template>
                    <template v-slot:content>
                        <hb-form label="Schedule Task">
                            <hb-link>+ Click to Add</hb-link>
                            <!-- <p class="my-1 text--secondary">1. Select start date</p>
                            <hb-date-picker
                                @click:clear="search.input_date = ''"
                                label="MM/DD/YYYY"
                                clearable
                                dense
                                id="date"
                                data-vv-name="date"
                                name="date"
                                :hide_details="true"
                                >
                                </hb-date-picker>
                            <p class="my-1 text--secondary">2. Select frequency</p>
                            <HbSelect
                                :items="['Office','Fax']"
                                v-validate="'required|max:45'"
                                data-vv-scope="default"
                                data-vv-name="phone"
                                data-vv-as="Phone"
                                :error="errors.has('default.phone')"
                                placeholder="Select Phone"
                            /> -->
                        </hb-form>
                    </template>
                </hb-expansion-panel>
            </v-expansion-panels>
        </div>
    </div>
</template>

<script type="text/babel">

import api from "../../../assets/api";
import Loader from "../../assets/CircleSpinner.vue";
import HbDatePicker from '../../assets/HummingbirdDatepicker';
import { notificationMixin } from '@/mixins/notificationMixin.js';
import moment from 'moment';

export default {
    components: {
        Loader,
        'update-inventory': () => import('./UpdateInventory.vue'),
        'edit-product': () => import('./EditPropertyProduct.vue'),
        HbDatePicker
    },
    name: "PropertyMerchandise",
    props: ['propertyId', 'search','showAssignSlide','selectedTab'],
    mixins: [notificationMixin],
    data() {
        return {
            setTaskOption: true,
            selectedPanel: 0,
            bulkEditOption: '',
            tableColumns: [
                { text: "SKU", value: "sku" },
                { text: "Name", value: "name", sortable: false },
                { text: "Description", value: "description", sortable: false },
                { text: "Price", value: "default_price", sortable: false },
                { text: "Cost", value: "default_cost", sortable: false },
                { text: "Notification Threshold", value: "threshold", sortable: false },
                { text: "Inventory", value: "total_inventory", sortable: false },
                { text: "Total Cost of Product", value: "total_cost_product", sortable: false },
                { text: "Last Inventory Update", value: "last_inventory_update", sortable: false },
                { text: "Status", value: "status" },
                { text: "", value: "actions", sortable: false }
            ],
            property_merchandises: [],
            isLoadingData: false,
            pagination: {},
            query: '',
            property_id: '',
            showAssignProductModal: false,
            corporateTableColumns: [
                { text: "SKU", value: "sku" },
                { text: "Name", value: "name", sortable: false },
                { text: "Default Price", value: "default_price", sortable: false },
                { text: "Default Cost", value: "default_cost", sortable: false },
            ],
            corporateMerchandises: [],
            selectedRows: [],
            selectedProducts: [],
            isLoadingCorporateData: true,
            showUpdateInventoryModal: false,
            showEditProductModal: false,
            inventoryUpdateSelected: {},
            editSelected: {},
            searchProduct: '',
            deleteDialog: false,
            isDeleting: false,
            deleteItem: null,
            totalCost: '',
            drawerHeight: '100%',
            isBulkLoading: false

        }
    },
    mounted() {
        this.setDrawerHeight();
    },
    computed: {
        hasInventory() {
            return this.deleteItem && this.deleteItem.total_inventory ? true : false;
        }
    },
    watch: {
        propertyId(newValue, oldValue) {
            this.property_id = newValue;
            this.fetchData();
        },
        deleteDialog: function (newValue) {
            if (!newValue) {
                this.deleteItem = null;
            }
        }
    },
    methods: {
        fractionalValueEvent(params, index, field) {
            this.selectedProducts[index][field] = parseFloat(params).toFixed(2);
        },
        setDrawerHeight() {
            let documentHeight = document.body.clientHeight;
            let tableElement = document.getElementsByClassName('property-merchand-table');
            if (tableElement && tableElement[0]) {
                let tableTop = tableElement[0].getBoundingClientRect().top;
                this.drawerHeight = `${Math.ceil((documentHeight - tableTop) - 2)}px`;
                this.$emit('set-sidebar-height', documentHeight - tableTop);

            }
        },
        async fetchData() {
            try {
                this.isLoadingData = true;
                let path = api.PROPERTIES + (this.property_id) + '/products?type=product';
                let response = await api.get(this, path);
                if (!response?.products?.length) {
                    this.$emit('hide-filters', true);
                } else {
                    this.$emit('hide-filters', false);
                }
                this.property_merchandises = response?.products?.map(item => {
                    let summary_inventory = item.summary_inventory ? item.summary_inventory : null
                    let remaining_inventory = (summary_inventory && summary_inventory.remaining_inventory) ? summary_inventory.remaining_inventory : 0;
                    let statusMark = '';
                    if (remaining_inventory > item.inventory_threshold) {
                        statusMark = 'In Stock';
                    } else if (remaining_inventory <= item.inventory_threshold && remaining_inventory > 0) {
                        statusMark = 'Low Stock';
                    } else if (remaining_inventory <= 0) {
                        statusMark = 'Out of Stock';
                    }
                    return {
                        id: item.id,
                        sku: item.sku,
                        product_id: item.product_id,
                        name: item.name,
                        description: item.description,
                        default_price: item.price ? item.price.toFixed(2) : 0,
                        default_cost: summary_inventory ? Number.parseFloat(summary_inventory.average_weighted_cost).toFixed(2) : 0,
                        threshold: item.inventory_threshold ? item.inventory_threshold : 0,
                        total_inventory: remaining_inventory,
                        total_cost_product: summary_inventory ? Number.parseFloat((summary_inventory.average_weighted_cost * remaining_inventory)).toFixed(2) : 0,
                        // last_inventory_update: (new Date()).toISOString().split('T')[0],
                        last_inventory_update: summary_inventory && summary_inventory.modified_at ? summary_inventory.modified_at : '',
                        statusMark: statusMark,
                        summary_inventory: summary_inventory ? summary_inventory : null,
                        properties: item.merchandise_details && item.merchandise_details.Properties ? item.merchandise_details && item.merchandise_details.Properties : []
                    }
                });
                this.totalCost = response.product_summary && (response.product_summary.total_cost !== 'null' || response.product_summary.total_cost !== null) ? Number.parseFloat(response.product_summary.total_cost).toFixed(2) : 0;
            } catch (error) {
                console.error(error);
            } finally {
                this.isLoadingData = false;
                this.setDrawerHeight();
            }
        },
        async showAssignProductModalEvent() {
            try {
                this.showAssignProductModal = true;
                this.isLoadingCorporateData = true;
                let response = await api.get(this, api.MERCHANDISE);
                this.corporateMerchandises = response?.merchandise?.map(item => {
                    return {
                        id: item.id,
                        sku: item.sku,
                        product_id: item.product_id,
                        name: item.name,
                        default_price: item.price ? item.price : 0,
                        default_cost: item.default_cost ? item.default_cost : 0

                    }
                });
                this.corporateMerchandises = this.corporateMerchandises.filter(item => !this.property_merchandises.find(p_item => p_item.sku === item.sku))
            } catch (error) {
                this.showMessageNotification({ description: error });
            } finally {
                this.isLoadingCorporateData = false;
            }
        },
        async assignPropertiesEvent() {
            try {
                this.isLoadingCorporateData = true;
                let payload = {
                    Products: this.selectedRows.map(item => { return { id: item.product_id } }),
                    Properties: [{ id: this.property_id }],
                }
                await api.post(this, api.MERCHANDISE + 'assign', payload);
                this.selectedRows = [];
                this.showAssignProductModal = false;
                this.fetchData();
            } catch (error) {
                this.showMessageNotification({ description: error });
            } finally {
                this.isLoadingCorporateData = false;
            }
        },
        async updateInventoryEvent(params) {
            this.showUpdateInventoryModal = true;
            this.inventoryUpdateSelected = params;
        },
        editProductEvent(params) {
            this.editSelected = params;
            this.showEditProductModal = true
        },
        showDeleteDialogEvent(params) {
            this.deleteItem = params;
            this.deleteDialog = true;
        },
        async deleteProductEvent() {
            try {
                this.isDeleting = true;
                await api.delete(this, api.PROPERTIES, `${this.propertyId}/products/${this.deleteItem.id}`);
                this.deleteDialog = false;
                this.fetchData();
            } catch (error) {
                this.showMessageNotification({ description: error });
            } finally {
                this.isDeleting = false;
            }
        },
        trimTextEvent(params = {  value: '', limit: 10}) {
            let { value, limit } = params;
            if (value && value.length > limit)
                return value.substring(0, limit) + '...';
            return value;
        },
        async updateProductsBulkEvent() {
            const validationStatus = await this.$validator.validateAll();
            if (validationStatus) {
                try {
                    this.isBulkLoading = true;
                    let payload = {
                        "property_id": this.propertyId,
                        // "transaction_type": this.bulkEditOption === 'add-inventory' ? 'newly_added' : 'writeoff',
                        "transaction_type": 'newly_added',
                        "bulk_products" : this.selectedProducts.map(item => {
                            return {
                                "quantity": item.new_quantity,
                                "cost_per_item": item.new_price,
                                "product_id": item.id
                            }
                        })
                    }
                    console.log('payload :>> ', payload);
                    await api.post(this, api.MERCHANDISE + 'inventory-bulk-actions', payload);
                    this.hideSlideEvent();
                    this.fetchData();
                } catch (error) {
                    this.showMessageNotification({ description: error });
                } finally {
                    this.isBulkLoading = false;
                }
            }
        },
        async downloadReport() {
            try {
                let url = api.REPORTS + "download-pdf";
                let timeZone = moment.tz.guess();
                let filters = {
                    "search": {
                        "search": "",
                        "report_date": ""
                    },
                    "columns": [
                        {
                            "key": "merchandise_sku",
                            "width": 100,
                            "label": "SKU",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 1,
                            "column_type": "string",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "merchandise_name",
                            "width": 200,
                            "label": "Name",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 2,
                            "column_type": "string",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchanidse",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "merchandise_description",
                            "width": 200,
                            "label": "Description",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 2,
                            "column_type": "string",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchanidse",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "property_product_price",
                            "width": 100,
                            "label": "Price",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 3,
                            "column_type": "money",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "property_product_cost",
                            "width": 100,
                            "label": "Cost",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 4,
                            "column_type": "money",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "property_product_inventory",
                            "width": 100,
                            "label": "Inventory",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 5,
                            "column_type": "number",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "property_product_total_cost",
                            "width": 100,
                            "label": "Total Cost of Product",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 4,
                            "column_type": "money",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "property_product_notification_threshold",
                            "width": 100,
                            "label": "Notification Threshold",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 4,
                            "column_type": "number",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "merchandise_last_inventory_update",
                            "width": 100,
                            "label": "Last inevntory update",
                            "input": "timeframe",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 7,
                            "column_type": "date",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": {
                            "label": "",
                            "days": 0,
                            "period": "",
                            "relation": "",
                            "start_date": "",
                            "end_date": ""
                            },
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "property_product_stock_status",
                            "width": 100,
                            "label": "Status",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 7,
                            "column_type": "string",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": {
                            "label": "",
                            "days": 0,
                            "period": "",
                            "relation": "",
                            "start_date": "",
                            "end_date": ""
                            },
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        }
                    ],
                    "sort": {
                        "field": "product_name",
                        "dir": "ASC"
                    },
                    "pivot_mode": {
                        "type": "",
                        "column": {},
                        "row": {},
                        "pivot_field": {},
                        "agg_method": ""
                    },
                    "groups": [],
                    "limit": 50,
                    "page": 1,
                    "offset": 0,
                    "roles_id": [this.primaryRoleId]
                }
                let data = {
                    "filters": JSON.stringify(filters),
                    "type": "generic",
                    "report_type": "property_products",
                    "format": "web",
                    "name": "Merchandise Property",
                    "timeZone": timeZone,
                    "roles_id": [this.primaryRoleId],
                    "properties": [this.property_id],
                }
                this.showMessageNotification({ type: 'success', description: 'Your download should begin shortly.' });
                let response = await api.post(this, url, data);
                const arr = new Uint8Array(response?.data);
                var blob = new Blob([arr], {type: 'application/pdf' });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download =  'merchandise-report';
                link.click();
            } catch (error) {
                this.showMessageNotification({ description: error });
            }
        },
        hideSlideEvent() {
            this.selectedProducts = [];
            this.bulkEditOption = '';
            this.$emit('hide-slide');
        }
    },
    destroyed(){
        this.errors.clear();
        this.$validator.reset();
        this.$validator.errors.clear();
    }
}
</script>

<style lang="scss" scoped>
.no-data-box {
    padding: 30px 0;
    text-align: center;
    border: 1.5px dashed #ccc;
    border-radius: 3px;
}

.cm-footer {
    border: thin solid rgba(0, 0, 0, 0.12);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.merchandise-drawer {
    height: 100%;
    top: 36px;
    transform: translateX(0%);
    z-index: 11;
    width: 50%;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}

.top-2px {
    position: relative;
    top: 2px;
}

.button-option {
    border: 1px solid #C4CDD5;
    border-radius: 4px;
    background-color: #FFF;
}

.button-option .btn-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.btn-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.bulk-edit-item {
    background-color: #FFF;
    border-bottom: 1px solid #e1e6eb;
}
</style>